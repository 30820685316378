<template>
  <div class="home">
    <Section light>
      <h1>Mechanical</h1>
      <v-col class="px-4" cols="12">
        <p>
          The main goal of the mechanical subteam is to provide a collaborative
          learning environment for members of all levels of experience by
          designing and producing successful physical components of the rover.
        </p>
        <v-img
          :aspect-ratio="1 / 1"
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/mechanical/overview.jpg')"
        />
        <br />
        <p>
          Our initial design phase is based on discussion and research in a
          collaborative environment. We use SOLIDWORKS to model components and
          create the full rover assembly, which are imported into ANSYS, Altair
          Inspire, and MotionView for FEA and MBD analyses. We emphasize
          designing for manufacturability and performance on the rover.
        </p>

        <p>
          Rover components are also manufactured by our team members, using
          machinery and tools such as:
        </p>
        <ul>
          <li>CNC and manual mills</li>
          <li>3D printers</li>
          <li>Laser cutters</li>
          <li>Bandsaws</li>
          <li>Drill presses</li>
          <li>Power and hand tools</li>
          <li>Welding</li>
        </ul>
      </v-col>
      <v-col class="px-4 col-md-6 mt-5">
        <h2>Arm Module</h2>
        <p>
          Our current arm was fully redesigned and built from scratch during the
          2021-2022 year. This year, we aim to completely and fundamentally
          redesign the entire arm, aiming to improve its precision, reliability,
          and optimization. Some of the requirements for the arm demanded by the
          competition include:
        </p>
        <ul>
          <li>Lifting heavy (5kg+) objects</li>
          <li>Typing on a keyboard</li>
          <li>Manipulating tools and switches</li>
          <li>Inserting a USB stick</li>
          <li>Collecting soil samples</li>
        </ul>
        <v-img
          :aspect-ratio="1 / 1"
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/mechanical/arm.jpg')"
        />
      </v-col>
      <v-col class="px-4 col-md-6 mt-5">
        <h2>Science Module</h2>
        <p>
          The science team communicates their research and design needs so that
          the mechanical team can design the hardware to implement the necessary
          experiments for competition. Last year, we built a carousel of
          cuvettes. A custom scoop deposited soil samples into a large funnel
          that separated the sample into four cuvettes so that multiple tests
          could be run on one sample. The carousel rotated after every sample
          was deposited allowing us to run the same four tests on multiple
          samples. A cache collection system was also designed by mechanical so
          that a sample could be collected and returned to the judges. Each
          individual cuvette had to be mixed, kept away from light, and spill
          proof.
        </p>
        <v-img
          :aspect-ratio="1 / 1"
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/mechanical/science.jpg')"
        />
      </v-col>
      <v-col class="px-4" cols="12">
        <h2>Chassis</h2>
        <p>
          Our current chassis was designed and built during the 2022-2023 school
          year. Our new lightweight, aluminum TIG welded chassis sports a
          rocker-bogie suspension system with a sturdy differential bar. With
          these upgrades, Lethargy easily tackled steep angles, rocky hills, and
          rough terrain during the URC.
        </p>
        <v-img
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/mechanical/comp_chassis.jpg')"
        /><br />
      </v-col>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Mechanical',
  components: {
    Section,
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
h2 {
  text-align: center;
}
</style>
